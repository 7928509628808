






















import Breadcrumb from '@/components/Breadcrumb.vue';
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
import ScrollTopArrow from '@/components/ScrollTop.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { Navbar, Footer, Breadcrumb, ScrollTopArrow },
})

export default class Main extends Vue {

}
