






import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})

export default class ScrollTopArrow extends Vue {
  public visible: boolean = false;

  public scrollTop() {
    window.scroll(0, 0);
  }

  public onScroll() {
   this.visible = window.scrollY > 150;
  }

  public mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

}
