// @todo
export const it: any = {
  messages: {
    home: {
      show_all_button: 'Mostra tutti',
    },
    footer: {
      contacts: 'Contatti',
    },

    tooltip: {
      choose_language: 'Seleziona una lingua',
      home: 'Vai alla home',
      logout: 'Logout',
      login: 'Entra',
      admin: 'Sezione admin',
      menu: 'Attiva o disattiva il menu',
    },

    menu: {
      search: 'Ricerca',
      search_placeholder: 'Cerca una mappa nel catalogo',
      home: 'Home',
      maps: 'mappe',
      info: 'info',
      login: 'Login',
      logout: 'Logout',
      admin: 'Admin',
      data: 'Dati',
      group: 'Gruppi',
      organization: 'Organizzazioni',
      map: 'Mappa',
      toggle: 'MENU',
      catalog: 'Catalogo',
      404: 'Pagina non trovata',
    },

    login: {
      username: 'Username',
      password: 'Password',
      submit: 'Accesso',
      title: 'Accedi al sistema di amministrazione',
      reset_password_url: 'Password dimenticata?',
      requiredField: 'Questo campo è obbligatorio',
      erroreLogin: 'Errore di login',
    },

    language: {
      it: 'Italiano',
      en: 'English',
    },

    maps: {
      group: 'Gruppo',
      edit: 'Modifica',
      view: 'Visualizza',
    },

  },
};
