











































































































































import { Info } from '@/types/TInfo';
import dialogPolyfill from 'dialog-polyfill';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: { },
  computed: {
    ...mapGetters({
      info: 'info/info',
    }),
  },
})

export default class Footer extends Vue {
  public info!: Info;

  public mounted() {
    dialogPolyfill.registerDialog((window as any).document.querySelector('#credits-modal'));
  }

  public showModal() {
    (window as any).document.querySelector('#credits-modal').showModal();
  }
}
