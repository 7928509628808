



















































































































































































import config from '@/config';
import { Info } from '@/types/TInfo';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: { },
  computed: {
    ...mapGetters({
      sections: 'settings/portalSections',
      showAdmin: 'settings/showAdminButton',
      info: 'info/info',
    }),
  },
})

export default class Navbar extends Vue {

  public sections!: string[];
  public showAdmin!: boolean;
  public info!: Info;

  public g3w_logo: string = require('@/assets/img/logo_g3wsuite-bw.png');
  public flag_it: string  = require('@/assets/img/flags/it_IT.png');
  public flag_en: string  = require('@/assets/img/flags/en_GB.png');

  public secondaryMenuVisible: boolean | null = true;

  get languages() {
    return config.languages;
  }

  get whoIs() {
    return this.$store.getters['me/me'];
  }

  get isLoggedIn() {
    return this.$store.getters['me/isLoggedIn'];
  }

  get hasNavBarTop(): boolean {
    return false !== (window as any).PORTAL_NAVBAR_TOP;
  }

  public logout() {
    // redirect to custom logout page
    const { logout_url, login_url } = this.$store.getters['info/info'];
    if ('login' !== login_url) {
      location.href = logout_url;
      return false;
    }
    // default logout
    this.$store.dispatch('me/logout', { locale: this.$i18n.locale });
  }

  public mounted() {
    this.$store.dispatch('me/fetchWhoAmI', { locale: this.$i18n.locale });
  }

  public toggleSecondaryMenu() {
    this.secondaryMenuVisible = this.secondaryMenuVisible ? null : true;
  }
}
